.home-rounded {
    border-top-left-radius: 0%;
    border-top-right-radius: 0%;
    border-bottom-left-radius: 20%;
    border-bottom-right-radius: 20%;
}

@media (min-width: 768px) {
    .home-rounded {
        border-top-left-radius: 0%;
        border-top-right-radius: 20%;
        border-bottom-left-radius: 0%;
        border-bottom-right-radius: 20%;
    }
}


