@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Museo';
    src: url('../fonts/MuseoSans-100.otf') format("truetype");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: url('../fonts/MuseoSans-300.otf') format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: url('../fonts/MuseoSans_500.otf') format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: url('../fonts/MuseoSans_700.otf') format("truetype");
    font-weight: 700;
    font-style: normal;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

html, body{
	scroll-behavior: smooth;
	@apply h-full;
}

.root-content,
#App,
main {
  	@apply h-full;
}

body {
  	@apply bg-body text-white font-museo;
}

.error-message {
	@apply text-red-700 mt-2 block;
}

.navigate-item {
	@apply bg-transparent text-dark border-none transition-colors hover:text-theme;
}

.marker-public {
	background-image: url('../img/pin-blue.png');
	@apply bg-cover w-9 h-11 cursor-pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
	@apply rounded-md !important;
}

.mapboxgl-popup-content {
	@apply text-dark text-center font-museo;
}

.mapboxgl-popup-close-button {
	@apply text-lg !important;
}

/*.title-card-result {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}*/

.h-content-webkit {
	height: -webkit-fill-available;
	height: inherit;
}

body.swal2-height-auto {
	@apply h-full !important;
}

.h-map-details {
	@apply h-96;
}

@media (min-width: 768px) {
    .h-map-details {
		height: 550px;
	}
}

.more-info-details ul {
    list-style: disc;
    margin: revert;
    padding: revert;
}

.more-info-details ol {
    list-style: auto;
    margin: revert;
    padding: revert;
}

.more-info-details h2 {
    @apply font-bold text-2xl;
}

.more-info-details h3 {
    @apply font-bold text-xl;
}

.more-info-details h4 {
	@apply font-bold;
}

.title-accordion {
    @apply bg-transparent cursor-pointer;
}

.title-accordion-preload {
    @apply bg-slate-700 opacity-20 animate-pulse rounded-2xl;
}

.title-accordion,
.title-accordion-preload {
    @apply font-bold text-white px-4 py-2 relative flex items-center w-full text-left xl:text-lg lg:text-sm text-base;
    overflow-anchor: none;
}

.title-accordion::after {
    font-family: 'FontAwesome';
    content: "\f053";
    @apply shrink-0 ml-auto w-5 h-5 transition-transform flex justify-center items-center;
    transform: rotate(-90deg);
}

.content {
    @apply bg-transparent overflow-hidden text-white rounded-2xl;
    max-height: 0;
    transition: max-height 0.5s;
}

.toggle:checked + .title-accordion + .content {
    max-height: 1000px;
}

.toggle:checked + .title-accordion::after {
    transform: rotate(-180deg) !important;
}

